import { render, staticRenderFns } from "./ShowCase.vue?vue&type=template&id=7c0404d6&scoped=true&lang=pug&"
import script from "./ShowCase.vue?vue&type=script&lang=js&"
export * from "./ShowCase.vue?vue&type=script&lang=js&"
import style0 from "./ShowCase.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ShowCase.vue?vue&type=style&index=1&id=7c0404d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c0404d6",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QInfiniteScroll,QSpinner,QSkeleton,QResponsive,QCard,QImg,QSeparator,QCardActions,QDialog,QStepper,QTabs,QTab});
