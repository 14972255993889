<template lang="pug">
q-page(style="margin-top: -35px")
  .bg-primary.text-white.row(ref="toolbar")
    .lt-md
    .col
      .row
        .col-12.text-center
          .q-pa-md.q-mt-xl.q-mb-lg
            .text-h1 {{ $t("activity-showcase") }}
            .text-h6 {{ $t("the-latest-activities-submitted-during-limitless-academy") }}

  #mTarget.bg-white.q-ma-md
    //- div {{ activities }}
    q-btn(
      flat,
      dense,
      color="primary",
      @click="selectedDay = -1",
      v-if="selectedDay > 0",
      :icon="matArrowBack"
    ) All activities
    q-infinite-scroll(@load="onLoad", scroll-target="#mTarget", ref="scroller")
      template(v-slot:loading)
        .row.justify-center.q-my-md
          q-spinner(size="lg")

      .row(v-for="day of sortedKeys")
        .col-12
          .row.justify-between.q-my-lg.items-top
            .col
              .text-h6 {{ getActivityTitle(day) }}
              .text-body2.text-grey-8 A random selection...
            .col-auto
              q-btn(
                flat,
                dense,
                color="primary",
                @click="selectedDay = day",
                v-if="selectedDay === -1"
              ) view all

        .col
          .row.q-col-gutter-md
            .col-12.col-sm-6.col-md-4.col-lg-3(
              v-if="activities[day] && activities[day].length === 0",
              v-for="i in 3"
            )
              q-skeleton(animation="none")
                q-responsive(:ratio="16 / 9")
        .col-12
          .row.q-col-gutter-md
            .col-12.col-sm-6.col-md-4.col-lg-3(
              v-for="activity of activities[day]",
              v-if="canRender(activity.name)"
            )
              //- div {{ activity }}
              q-card(
                flat,
                bordered,
                style="border-radius: 5px; overflow: hidden",
                :ratio="16 / 9"
              )
                div
                  .cursor-pointer(
                    v-if="isImage(activity.name)",
                    @click="dialog(activity.url)"
                  )
                    q-img(:ratio="16 / 9", :src="activity.url")
                    //- div {{ activity.url }}
                    .absolute-bottom-right

                  .cursor-pointer(
                    v-else-if="isPDF(activity.name)",
                    @click="dialog(activity.url)"
                  )
                    q-img(:ratio="16 / 9", :src="getPDFThumb(activity.name)")
                    .absolute-bottom-right

                  q-responsive(
                    v-else-if="isVideo(activity.name)",
                    :ratio="16 / 9"
                  )
                    video(:src="activity.url", controls)

                  q-responsive(
                    v-else-if="isAudio(activity.name) && activity.url",
                    :ratio="16 / 9"
                  )
                    .text-center
                      av-circle.q-ma-sm(
                        audio-class="player",
                        :bar-width="4",
                        ref="audioObj",
                        :cors-anonym="true",
                        :line-width="3",
                        outline-color="rgb(34, 103, 54)",
                        :canv-width="getCanvDim()",
                        :canv-height="getCanvDim()",
                        progress-color="rgb(34, 103, 54)",
                        bar-color="rgb(34, 103, 54)",
                        :audio-src="activity.url"
                      )
                  div(v-else)
                    q-skeleton
                      q-responsive(:ratio="16 / 9")
                q-separator
                q-card-actions(align="between")
                  q-btn(
                    @click="dialog(activity.url)",
                    :icon="matFullscreen",
                    flat,
                    v-if="!isVideo(activity.name)"
                  )
                  div(v-if="isVideo(activity.name)")
                  q-btn(@click="share(activity.url)", :icon="matShare", flat)
            .col-12.col-sm-6.col-md-4.col-lg-3
              q-card(
                flat,
                bordered,
                style="border-radius: 5px; overflow: hidden",
                :ratio="16 / 9",
                v-if="selectedDay === -1"
              )
                q-responsive(:ratio="16 / 9")
                  q-btn.full-width(
                    flat,
                    dense,
                    color="primary",
                    @click="selectedDay = day"
                  ) See more...

  q-dialog(v-model="imgFullscreen")
    q-card(style="width: 700px; max-width: 90vw; max-height: 90vh")
      q-img(:src="selectedImage", contain, v-if="isImage(selectedImage)")
      iframe(
        :src="selectedImage",
        v-if="isPDF(selectedImage)",
        style="border: none; width: 100%; height: 90vh"
      )
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { DateTime } from "luxon";
import { map, mapValues, orderBy, reverse } from "lodash";
import { mapState, mapActions } from "vuex";
import Language from "@/mixins/Language";
import { loadLanguageAsync } from "../i18n";
import { firebaseurl } from "../db";
import { copyToClipboard } from "quasar";
// import { AvLine } from "vue-audio-visual";
let lang_rtl = {
  ur: "ar",
  fa: "ar",
  pa: "ar",
  ar: "ar",
};

let axios;

export default {
  mixins: [Language],
  components: { Breadcrumbs },
  data: function () {
    return {
      activities: {},
      urls: {},
      page: 0,
      imgFullscreen: false,
      selectedImage: "",
      selectedDay: -1,
    };
  },
  computed: {
    ...mapState(["config"]),
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    sortedKeys() {
      // console.log(Object.keys(this.activities));
      // console.log(Object.keys(this.activities).map(parseInt));
      return reverse(orderBy(Object.keys(this.activities), "asc"));
    },
  },
  async mounted() {
    this.currentlang = this.$i18n.locale;
    await Promise.all([this.initConfig()]);
    // this.onLoad();
  },
  watch: {
    selectedDay() {
      console.log(this.selectedDay);

      // console.log("resetting");
      this.page = 0;
      this.$refs.scroller.stop();
      this.$refs.scroller.reset();
      this.$refs.scroller.resume();
      this.$refs.scroller.trigger();
      // console.log("triggered");
      //scroll to top!
      window.scrollTo(0, 0);
    },
    "$i18n.locale"() {
      // console.log(this.$i18n.locale);
      this.currentlang = this.$i18n.locale;
    },
    async currentlang() {
      await loadLanguageAsync(this.currentlang);

      if (lang_rtl[this.currentlang]) {
        let lang = await import("quasar/lang/ar");
        this.$q.lang.set(lang.default);
      } else {
        let lang = await import("quasar/lang/en-us");
        this.$q.lang.set(lang.default);
      }

      // this.$i18n.locale = this.currentlang;
    },
  },
  methods: {
    getActivityTitle(day) {
      switch (day) {
        case "10":
          return this.$t("p2_leadership_1_title");
        case "8":
          return this.$t("p2_iteration_1_title");
        case "6":
          return this.$t("p2_testing_1_title");
        case "4":
          return this.$t("p2_prototyping_1_title");
        case "2":
          return this.$t("p2_climate_1_title");
      }
    },
    share(url) {
      try {
        copyToClipboard(url);
        this.$q.notify("Link copied!");
      } catch (e) {
        this.$q.notify({
          type: "negative",
          message: this.$t("editing_error"),
        });
      }
    },
    dialog(act) {
      this.imgFullscreen = true;
      this.selectedImage = act;
    },
    getCanvDim() {
      return 120;
    },
    canRender(file) {
      return !(
        file.endsWith(".docx") ||
        file.endsWith(".doc") ||
        file.endsWith(".pptx") ||
        file.endsWith(".7z") ||
        file.endsWith(".DS_Store")
      );
    },
    isImage(file) {
      return (
        file.endsWith(".png") ||
        file.endsWith(".jpg") ||
        file.endsWith(".jpeg") ||
        !(this.isVideo(file) || this.isAudio(file) || this.isPDF(file))
      );
    },
    isVideo(file) {
      return (
        file.toLowerCase().endsWith(".mp4") ||
        file.toLowerCase().endsWith(".mov")
      );
    },
    isAudio(file) {
      return (
        file.endsWith(".mp3") ||
        file.endsWith(".aac") ||
        file.endsWith(".m4a") ||
        file.endsWith(".mpeg")
      );
    },
    isPDF(file) {
      return file.endsWith(".pdf");
    },
    getPDFThumb(pdf) {
      // console.log(`${firebaseurl}/api/getpdfthumb/${pdf}`);
      return `${firebaseurl}/api/getpdfthumb/${pdf}`;
    },
    ...mapActions(["initConfig"]),
    async onLoad(index, done) {
      console.log("loading");
      if (!axios) axios = await require("axios");
      try {
        let lastlength = -1;
        // console.log(first(this.activities));
        if (this.selectedDay > 0) {
          lastlength =
            this.selectedDay === -1
              ? -1
              : this.activities[this.selectedDay].length;
        }

        const activities = (
          await axios.get(
            `${firebaseurl}/api/activities/phase_2?page=${this.page}&day=${this.selectedDay}`
          )
        ).data;

        // console.log(this.page);

        const mapped = mapValues(activities, (b) =>
          map(b, (a) => {
            return {
              name: a,
              url: "",
            };
          })
        );

        // console.log(mapped);

        // const shuffled = shuffle(mapped);

        if (this.selectedDay > 0) {
          // console.log("selectedday");
          if (this.page === 0) this.$set(this, "activities", mapped);
          else
            this.activities[this.selectedDay].push(...mapped[this.selectedDay]);
        } else this.$set(this, "activities", mapped);

        // this.activities.push(...mapped);
        this.page++;

        // console.log(this.activities);
        // console.log(shuffled)

        //for each of the loaded ones, push onto queue:
        // let promises = [];
        // console.log(a.name);
        // promises.push(async ()=>{
        // console.log(a.name);
        for (let a in this.activities)
          for (let b of this.activities[a]) {
            // console.log(b);
            b.url = (
              await axios.get(`${firebaseurl}/api/activitysrc/${b.name}`)
            ).data.url;
          }
        // console.log(a);
        // return a;
        // });

        // console.log(promises)
        // await Promise.all(promises);

        // console.log("done");

        if (this.selectedDay > 0) {
          const currentlen = this.activities[this.selectedDay].length;
          // console.log(lastlength == currentlen);
          done(lastlength == currentlen);
        } else {
          done(true);
        }

        // done(lastlength == currentlen);

        // done();
      } catch (error) {
        console.error(error);
      }
    },

    localiseDateExact(str) {
      let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
      return dt.toLocaleString({ ...DateTime.DATE_FULL });
    },
    localiseDate(str) {
      let dt = DateTime.fromFormat(str, "dd/MM/yyyy");
      return dt.toLocaleString({ month: "long", year: "numeric" });
    },
    localiseDateRange(str1, str2) {
      let dt1 = DateTime.fromFormat(str1, "dd/MM/yyyy");
      let dt2 = DateTime.fromFormat(str2, "dd/MM/yyyy");

      return `${dt1.toLocaleString({
        month: "long",
      })} - ${dt2.toLocaleString({ month: "long", year: "numeric" })}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.variables.scss";
.q-stepper__dot {
  // width: 8px !important;
  min-width: inherit !important;
  width: 16px !important;
  height: 16px !important;
  color: black;
  border-radius: 0 !important;
}

.q-stepper__dot:after,
.q-stepper__dot:before {
  background-color: $accent !important;
  width: 4px !important;
  transform: translateX(-2px);
}

.q-stepper__title {
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
}

.q-stepper__caption {
  color: $secondary;
  font-size: 1rem !important;
  line-height: 1.6rem !important;
}

.q-tabs--vertical .q-tab {
  // color: red;
  text-align: left !important;
  display: block !important;
  white-space: inherit !important;
}

.q-tab__label {
  text-align: left !important;
}

.player {
  width: 100%;
  display: block;
}
</style>

<style lang="scss" scoped>
@import "@/styles/quasar.variables.scss";

.hero1 {
  min-height: 700px;
  position: relative;
}

.hero2 {
  height: 100%;
  // background: rgb(21, 0, 36);
  background-image: url("/img/academy1.webp"),
    linear-gradient(285deg, $accent 63%, $primary 63.2%);
  // background-image: linear-gradient(285deg, #d9bad7 63%, $secondary 63.2%);
  background-repeat: no-repeat;
  background-position: bottom -40px right 40px, center;
  // background-position-y: bottom, center;
  // background-position-x: right;
  background-size: 80%, 100%;
}

.hero4 {
  background-image: url("/img/hero4.webp");
  height: 100%;
  min-height: 50vw;
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: bottom -10px right;
  // background-position-x: left;
}

.hero3 {
  background-image: url("/img/academy1.webp");
  height: 100%;
  min-height: 40vw;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom -10px left -30px;
  // background-position-x: left;
}

.hero3a {
  background-image: url("/img/academy1.webp");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom -10px left -30px;
  opacity: 0.1;
  position: absolute;
  background-size: 100vw;
  bottom: 0;
  left: 0;
}

.hero-border {
  // border-bottom: $lilac 60px solid;
  background-color: $lilac;
  // background: url("~@/assets/img/lilacborder.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -30px;
  height: 60px;
  // left: -1px;
  width: 100%;
  transform: skew(0, -0.5deg);
  overflow: hidden;
}

.hero2a {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  // background-color: red;
  background-image: url("/img/academy1.webp");
  opacity: 0.1;
  background-repeat: no-repeat;
  // background-position: right top;
  background-position: right -150px bottom -40px !important;
  // background-position-x: right30%;
}

.fixme {
  top: 70px;
  position: fixed;
  z-index: 1000;
  border-bottom: 1px solid white;
}
</style>
