<template lang="pug">
q-breadcrumbs(gutter="xs").q-mt-xl.q-pt-lg.q-ma-dynamic
  template(v-slot:separator)
    q-icon(
        size="1.3em"
        :name="matChevronRight"
        color="accent"
        )
  q-breadcrumbs-el.text-white(:label="$t('home')" to="/")
  q-breadcrumbs-el.text-accent(:label="title")
  //- q-breadcrumbs-el.text-primary(:label="subtitle" v-if="subtitle")
</template>

<script>
  export default {
    props: ["title", "subtitle"],
  };
</script>
